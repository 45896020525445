<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" :model="filters">
				<span>
					<el-form-item class="mb10" label="用户名">
						<el-input v-model.trim="filters.username" placeholder="输入用户名" clearable></el-input>
					</el-form-item>
					<el-form-item label="订单号">
						<el-input v-model.trim="filters.pay_order_id" placeholder="输入订单号" clearable></el-input>
					</el-form-item>
					<el-form-item label="付款人">
						<el-input v-model.trim="filters.payer" placeholder="输入付款人" clearable></el-input>
					</el-form-item>
					<el-form-item label="付款类型">
					  <el-select v-model="filters.pay_company" placeholder="请选择付款类型" clearable>
						  <el-option v-for="item in payType" :key="item.label" :label="item.value" :value="item.label"></el-option>
					  </el-select>
				  </el-form-item>
				  <el-form-item label="付款状态">
					  <el-select v-model="filters.order_status" placeholder="请选择付款状态" clearable>
						  <el-option v-for="item in payStatus" :key="item.label" :label="item.value" :value="item.label"></el-option>
					  </el-select>
				  </el-form-item>
					<el-form-item label="时间类型">
						<el-select v-model="filters.time_type" placeholder="请选择时间类型" clearable>
							<el-option v-for="item in timeType" :key="item.label" :label="item.value" :value="item.label"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="周期">
						<el-date-picker v-model="time" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
					</el-form-item>
					<!-- <el-form-item label="订单类型">
						<el-input v-model="filters.order_status" placeholder="选择状态"></el-input>
					</el-form-item> -->
					<el-form-item label="订单内容">
						<el-select v-model="filters.order_version" placeholder="请选择付款类型" clearable>
							<el-option v-for="item in versionList" :key="item.label" :label="item.value" :value="item.label"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">查询</el-button>
					</el-form-item>
				</span>
				<el-button type="primary" @click="create()" style="height:40px">创建订单</el-button>
			</el-form>			
		</el-col>

		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="create_at" label="生成时间" sortable width="120">
			</el-table-column>
			<el-table-column prop="order_confirm_at" label="订单确认时间" width="120">
			</el-table-column>
			<el-table-column prop="id" label="订单号" width="120">
			</el-table-column>
			<el-table-column prop="payer" label="付款人" width="100">
			</el-table-column>
			<el-table-column prop="username" label="用户名" width="100">
			</el-table-column>
			<el-table-column prop="pay_amount" label="付款金额" width="100">
			</el-table-column>
			<el-table-column prop="cycle_end" label="到期时间" width="120">
				<template slot-scope="scope">{{common(scope.row.cycle_end)}}</template>
			</el-table-column>
			<el-table-column prop="server_type_desc" label="订单内容" width="180">
			</el-table-column>
			<el-table-column prop="pay_company" label="付款类型" width="100">
			</el-table-column>
			<el-table-column prop="order_status_desc" label="付款状态" width="100">
			</el-table-column>
			<el-table-column prop="remark" label="备注" width="100">
			</el-table-column>
			<el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<template>
						<el-button v-if="scope.row.pay_company_i == 2 && scope.row.order_status == 1" type="primary" plain size="small" @click="modifyOrder(scope.row)">修改订单</el-button>
						<el-button v-if="scope.row.pay_company == 3 && scope.row.order_status == 1" type="primary" plain size="small" @click="confirmOrder(scope.row)">订单确认</el-button>
						<!-- <el-button v-if="scope.row.action == '退款'" type="primary" plain size="small" @click="handleLog(scope.row.id)">退款</el-button>	 -->
					</template>
				</template>
			</el-table-column>
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!-- <el-button type="success">导出列表</el-button> -->
			<div class="left">总金额：{{totalAmount}}</div>
			<!--分页-->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-col>
		<el-dialog title="订单信息" :visible.sync="dialogShow" width="600px" center>
			<el-form :model="storeInfo" label-width="90px">
				<el-form-item label="订单号">
					<p>{{storeInfo.id}}</p>
				</el-form-item>
				<el-form-item label="用户名">
					<p>{{storeInfo.username}}</p>
				</el-form-item>
				<el-form-item label="付款人">
					<el-input v-model="storeInfo.payer" placeholder="输入付款人" class="w300"></el-input>
				</el-form-item>
				<el-form-item label="付款金额">
					<el-input v-model="storeInfo.pay_amount" placeholder="输入付款金额" class="w300"></el-input>
				</el-form-item>
				<el-form-item label="付款类型">
					<el-select v-model="storeInfo.pay_company_i" placeholder="请选择付款类型" clearable>
						<el-option v-for="item in payType" :key="item.label" :label="item.value" :value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="付款状态">
					<el-select v-model="storeInfo.order_status" placeholder="请选择付款状态" clearable>
						<el-option v-for="item in payStatus" :key="item.label" :label="item.value" :value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单内容">
					<!-- <el-input v-model="storeInfo.domain" placeholder="输入域名" class="w300"></el-input>月 -->
					<el-select v-model="storeInfo.server_type" placeholder="请选择订单内容" clearable>
						<el-option v-for="item in serverType" :key="item.label" :label="item.value" :value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单备注">
					<el-input v-model="storeInfo.remark" placeholder="输入备注" class="w300"></el-input>
				</el-form-item>
				
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="confirm">确 认</el-button>
				<el-button @click="noConfirm">取 消</el-button>
			</span>
		</el-dialog>
	</section>
</template>

<script>
import common from '@/utils/common';
import {payStatus,payType,serverType,versionList} from '@/utils/const';
	import {
		orderList, confirmByHand
	} from '../../api/api';
	import {
		getTime
	} from '../../../static/help';
	export default {
		data() {
			return {
				serverType,
				payType,
				payStatus,
				versionList,
				time:[],
				timeType:[
					{
						value:'生成时间',
						label: '1',
					},{
						value:'确认时间',
						label: '2',
					}
				],
				dialogShow: false,
				filters: {
					time_type: '',
					cycle_start: '',
					cycle_end: '',
					order_status: '',
					order_version: '',
					username: '',
					pay_company: '',
					pay_order_id: '',
					payer: '',
				},
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10, //每页条数
				pagesizes: [10, 20, 50, 100], //可选条数
				listLoading: false,
				storeInfo: {
					id: '',
					username: '',
					payer: '',
					pay_amount: '',
					pay_company: '',
					order_status: '',
					server_type: '',
					remark:''
				},
				totalAmount:''
			}
		},
		watch:{
			'time': function(newValue,oldVale){
				console.log(this.time)
				this.filters.cycle_start = this.time[0]
				this.filters.cycle_end = this.time[1]
			},
		},
		methods: {
			common(time){
				return common.dateFormater('YYYY-MM-DD', time * 1000)
			},
			confirm(){
				confirmByHand(this.storeInfo).then((res) => {
					if(res.code == 1) {
						this.dialogShow = false
						this.getList();
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			// 创建订单
			create(){
				this.$router.push({name: '创建订单',})
			},
			noConfirm(){
				this.dialogShow = false
				this.storeInfo = {};
			},
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取用户列表
			getList(val) {
				if(val == '0') this.page = 1
				let para = {
					...this.filters,
					page: this.page,
					pagesize: this.pagesize //分页条数
				};
				this.listLoading = true;
				orderList(para).then((res) => {
					if(res.code == 1) {
						var data = res.data.res;
						// data.forEach(item =>{
						// 	item.create_at = getTime(item.create_at)
						// 	item.order_confirm_at = getTime(item.order_confirm_at)								
						// })
						this.total = res.data.page.pagecount;
						this.listData = data;
						this.listLoading = false;
						this.totalAmount =  res.data.total
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			modifyOrder: function(data) {
				this.dialogShow = true;
				this.storeInfo.id = data.id;
				this.storeInfo.username = data.username;
				this.storeInfo.payer = data.payer;
				this.storeInfo.pay_amount = data.pay_amount;
				this.storeInfo.pay_company_i = data.pay_company_i;
				this.storeInfo.order_status = data.order_status;
				this.storeInfo.server_type = data.server_type;
				this.storeInfo.remark = data.remark;
				// this.storeInfo = this.deepCopy(data);
			},
			confirmOrder: function(amount, month, remark) {
				this.dialogShow = true;
			},
			seepayLog: function(index, row) {
				this.$router.push({
					name: '店铺付费日志',
					query: {
						id: row.id
					}
				})
			},
		},
		mounted() {
			//获取列表数据
			this.getList();
		}
	}
</script>

<style lang="scss" scoped>
	/* @import "../../styles/permission.scss"; */
	.left{ float: left; font-size: 14px; height: 28px; line-height: 28px; margin-top: 5px; }
</style>